import { useEffect } from 'react';
import { useAlterUiState, useUiState } from '../../contexts/UIStateContext';
import { DialogType, getDialogInfo } from '../dialogs/DialogContainer';
import { SiteFeatures, SubscriptionType, useSite } from '../../contexts/SiteContext';
import { useGet } from '../../utils/apiClient';
import { useCurrentUser } from '../../contexts/UserContext';
import { hasFlag } from '../../utils/utils';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { useClientSettings } from '../../contexts/ClientSettingsContext';

const defaultPartnerId = 1;

type NaggingBoxStorageDate = Date | null;

function NaggingBoxContainer(): JSX.Element {
	const alterUiState = useAlterUiState();
	const uiState = useUiState();
	const site = useSite();
	const user = useCurrentUser();
	const clientSettings = useClientSettings();

	const [shownDate, setShownDate] = useLocalStorage(`naggingbox_${user.cloudUserId}_${site.instanceGuid}`, null as NaggingBoxStorageDate);

	const { data, isLoading } = useGet<number>({
		queryName: 'getManagedDevices',
		path: '/webapi/devices/ManagedDeviceCount',
	});

	const mustShow = user.userLastLogon !== null && (shownDate === null || shownDate !== user.userLastLogon);

	useEffect(() => {
		/* Nagging box is shown (once per login) if there are 48 or more managed devices on a free site. Exclusions:
			1) PrivilegedFree site feature is on (configurable through MaPo)
			2) Site is not standalone Miradore
			3) User is MSP
		*/
		if (
			mustShow &&
			uiState.activeDialog.type == DialogType.None &&
			site.subscription === SubscriptionType.Free &&
			site.partnerId === defaultPartnerId &&
			!site.isConnectedToMSP &&
			!hasFlag(site.siteFeatures, SiteFeatures.PrivilegedFree) &&
			!isLoading &&
			data &&
			data >= clientSettings.freePlanLowerDeviceLimit
		) {
			const dialogInfo = getDialogInfo(DialogType.NaggingBox, {
				count: data,
			});

			if (dialogInfo) {
				alterUiState.toggleDialog(dialogInfo);
			}

			setShownDate(user.userLastLogon);
		}
	}, [
		alterUiState,
		data,
		isLoading,
		mustShow,
		setShownDate,
		site.partnerId,
		site.siteFeatures,
		site.subscription,
		uiState.activeDialog.type,
		site.isConnectedToMSP,
		user.userLastLogon,
		clientSettings.freePlanLowerDeviceLimit,
	]);

	return <></>;
}

export default NaggingBoxContainer;

import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import commonDialogStyles from '../dialogs/ModalDialog.module.scss';
import styles from './NaggingBox.module.scss';
import { useAlterUiState, useUiState } from '../../contexts/UIStateContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as Alert } from '../../images/alert_square.svg';
import Button, { ButtonSize, ButtonTheme } from '../common/Button';
import { postEventData } from '../../utils/ServerUtils';
import { EventMetadataType, EventType } from '../../containers/eventDTO';
import Markdown from '../common/Markdown';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NaggingBoxPayload } from '../dialogs/DialogContainer';
import { useClientSettings } from '../../contexts/ClientSettingsContext';

export interface NaggingBox {
	title: string;
	description: string;
	buttonText: string;
	buttonLink: string;
	internalName: string;
}

function NaggingBox(): JSX.Element {
	const alterUiState = useAlterUiState();
	const navigate = useNavigate();
	const { activeDialog } = useUiState();
	const [t] = useTranslation();
	const clientSettings = useClientSettings();

	const managedDeviceCount = (activeDialog as NaggingBoxPayload).args.count;

	const handleClick = (dismiss: boolean) => {
		const metadata = {} as { [key: string]: string };
		metadata[EventMetadataType.Target] = dismiss ? 'Dismiss' : 'CTA';

		postEventData({
			eventType: EventType.NaggingBoxClicked,
			metadata: metadata,
		});

		if (!dismiss) {
			navigate('/Subscription');
		}

		alterUiState.closeDialog();
	};

	return (
		<div className={commonDialogStyles['modal-container']}>
			<div className={`${commonDialogStyles['modal-content-background-wave']} ${styles['nagging-box-modal-content']}`}>
				<div className={styles['modal-top-controls']}>
					<FontAwesomeIcon icon={faTimes} onClick={() => handleClick(true)} />
				</div>
				<div className={styles['modal-body']}>
					<div className={styles['nagging-box-icon']}>
						<Alert />
					</div>
					<div className={styles['nagging-box-content']}>
						<div className={styles['nagging-box-content-title']}>
							<Markdown
								content={
									managedDeviceCount > 50
										? t('dialogs.nagging_box.title_over_upper_limit', { deviceLimit: clientSettings.freePlanUpperDeviceLimit })
										: t('dialogs.nagging_box.title_between_lower_and_upper_limit')
								}
							/>
						</div>
						<div className={styles['nagging-box-content-description']}>
							<Markdown
								content={
									managedDeviceCount > 50
										? t('dialogs.nagging_box.description_over_upper_limit')
										: t('dialogs.nagging_box.description_between_lower_and_upper_limit', {
												deviceLimit: clientSettings.freePlanUpperDeviceLimit,
										})
								}
							/>
						</div>
						<div className={styles['nagging-box-cta']}>
							<Button
								theme={ButtonTheme.PrimaryNeutral}
								size={ButtonSize.Medium}
								content={t('dialogs.nagging_box.button')}
								onClick={() => handleClick(false)}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default NaggingBox;

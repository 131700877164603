import { createContext, ReactNode, useContext, useMemo, useReducer } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { isDevelopmentEnvironment } from '../utils/utils';
import { ReactQueryDevtools } from 'react-query/devtools';
import useIsMounted from '../utils/useIsMounted';

type ApiContextProps = {
	children: ReactNode;
};

export interface CacheState {
	notificationsModified: string | undefined;
	settingsModified: string | undefined;
	siteDataHash: string | undefined;
	userModified: string | undefined;
}

export interface AlterCacheState {
	updateCacheState(value: CacheState): void;
}

const queryClient: QueryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			staleTime: 5000,
		},
	},
});

const CacheStateContextDispatch = createContext<AlterCacheState | undefined>(undefined);

type Action = UpdateState;

type UpdateState = {
	type: 'UPDATE_STATE';
	newState: CacheState;
};

const cacheStateReducer = (state: CacheState, action: Action) => {
	switch (action.type) {
		case 'UPDATE_STATE':
			invalidateQueries(state, action.newState);
			return { ...action.newState };
		default:
			throw new Error();
	}
};

export function ApiContext({ children }: ApiContextProps): JSX.Element {
	const [, dispatch] = useReducer(cacheStateReducer, {
		notificationsModified: undefined,
		settingsModified: undefined,
		siteDataHash: undefined,
		userModified: undefined,
	});

	const isMountedRef = useIsMounted();

	const alterUiState = useMemo<AlterCacheState>(() => {
		return {
			updateCacheState: (value: CacheState) => {
				if (isMountedRef.current) {
					dispatch({
						type: 'UPDATE_STATE',
						newState: value,
					});
				}
			},
		};
	}, [isMountedRef]);

	return (
		<QueryClientProvider client={queryClient}>
			{isDevelopmentEnvironment() && <ReactQueryDevtools initialIsOpen={false} />}
			<CacheStateContextDispatch.Provider value={alterUiState}>{children}</CacheStateContextDispatch.Provider>
		</QueryClientProvider>
	);
}

export function useAlterCacheState(): AlterCacheState {
	const alterState = useContext(CacheStateContextDispatch);

	if (alterState === undefined) {
		throw new Error('Using useAlterCacheState() outside of CacheStateContextDispatch');
	}

	return alterState;
}

function invalidateQueries(cacheState: CacheState, newState: CacheState) {
	if (cacheState.notificationsModified && cacheState.notificationsModified !== newState.notificationsModified) {
		queryClient.refetchQueries('getNotificationCounts');
		queryClient.refetchQueries('getNotifications');
	}

	if (cacheState.settingsModified && cacheState.settingsModified !== newState.settingsModified) {
		queryClient.refetchQueries('getSiteSettings');
	}

	if (cacheState.siteDataHash && cacheState.siteDataHash !== newState.siteDataHash) {
		queryClient.refetchQueries('getSite');
	}

	if (cacheState.userModified && cacheState.userModified !== newState.userModified) {
		queryClient.refetchQueries('getCurrentUser');
	}
}

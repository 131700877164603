import { createContext, ReactNode, useContext, useEffect } from 'react';
import Spinner from '../components/common/Spinner';
import { useSiteSettings } from './SettingsContext';
import { useGet } from '../utils/apiClient';
import { useSignalR } from './SignalRContext';
import { navigateToLoginPage } from '../utils/utils';

type UserContextProps = {
	children: ReactNode;
};

export interface User {
	loginName: string;
	timeZone: string;
	timeZoneDisplayName: string;
	locale: string;
	language: string;
	userRole: UserRole;
	firstName: string;
	lastName: string;
	twoFactorAuthenticationEnabled: boolean;
	cloudUserId: number;
	identifier: string;
	customBannerDismissDate: Date | null;
	siteCount: number;
	marketingDialogDismissDate: Date | null;
	created: Date;
	userLastLogon: Date | null;
	hubSpotVid: string | null;
}

export enum UserRole {
	Unknown = 'Unknown',
	Admin = 'Admin',
	Editor = 'Editor',
	Reader = 'Reader',
	CompanyMember = 'CompanyMember',
}

const UserContextValues = createContext<User | undefined>(undefined);

export function UserContext({ children }: UserContextProps): JSX.Element {
	const siteSettings = useSiteSettings();
	const signalr = useSignalR();
	const { data: user, refresh } = useGet<User>({ queryName: 'getCurrentUser', path: '/webapi/account/currentUserData' });

	useEffect(() => {
		if (user !== undefined) {
			if (!user.twoFactorAuthenticationEnabled && siteSettings.twoFactorAuthenticationRequired) {
				navigateToLoginPage('/TwoFactorAuthenticationRequired');
			} else {
				signalr.subscribeToEvent('RefreshCurrentUser', refresh);
			}
		}
		return () => signalr.unsubscribeFromEvent('RefreshCurrentUser', refresh);
	}, [user, siteSettings.twoFactorAuthenticationRequired, signalr, refresh]);

	return user === undefined ? <Spinner loading /> : <UserContextValues.Provider value={user}>{children}</UserContextValues.Provider>;
}

export function useCurrentUser(): User {
	const user = useContext(UserContextValues);

	if (user === undefined) {
		throw new Error('Using useCurrentUser() outside of UserContextValues');
	}

	return user;
}

export const exportedStrictlyForTesting = {
	UserContextValues,
};

import NavMenu from './NavMenu';
import TitleBar from './titlebar/TitleBar';
import DialogContainer, { DialogType } from './dialogs/DialogContainer';
import styles from './Layout.module.scss';
import { useAlterUiState, useUiState } from '../contexts/UIStateContext';
import SetupGuideAssistant, { SetupAssistantVisibilityState } from './setup-assistant/SetupGuideAssistant';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import MiniProfiler from './MiniProfiler';
import BannerContainer from './banners/BannerContainer';
import useRouteMatching from '../utils/useRouteMatching';
import { useSiteSettings } from '../contexts/SettingsContext';
import DemoSiteTitleBar from './demo-site/DemoSiteTitleBar';
import { Outlet } from 'react-router-dom';
import MarketingDialogContainer from './marketing-dialog/MarketingDialogContainer';
import { useClientSettings } from '../contexts/ClientSettingsContext';
import { useSegmentPageView } from '../hooks/useSegmentPageView';
import { useSegmentIdentify } from '../hooks/useSegmentIdentify';
import { analytics } from '../utils/segment';
import NaggingBoxContainer from './nagging-box/NaggingBoxContainer';

function Layout(): JSX.Element {
	const { isDemoSite, showAutoTrialDialog } = useSiteSettings();

	const alterUiState = useAlterUiState();
	const uiState = useUiState();

	useSegmentPageView();
	useSegmentIdentify();

	const clientSettings = useClientSettings();
	analytics.load({ writeKey: clientSettings.segmentWriteKey });
	// This is utilized to make the UI respond immediately when close-button is clicked. Otherwise the
	// dialog wouldn't close until the setting gets updated and client refresh event is received.
	const [autoTrialDialogAlreadyShown, setAutoTrialDialogAlreadyShown] = useState(false);

	useEffect(() => {
		if (!autoTrialDialogAlreadyShown && showAutoTrialDialog && uiState.activeDialog.type !== DialogType.AutoTrial) {
			setAutoTrialDialogAlreadyShown(true);
			alterUiState.toggleDialog({ type: DialogType.AutoTrial });
		} else if (!showAutoTrialDialog && uiState.activeDialog.type === DialogType.AutoTrial) {
			alterUiState.closeDialog();
		}
	}, [uiState, alterUiState, autoTrialDialogAlreadyShown, showAutoTrialDialog]);

	return (
		<div>
			<DialogContainer />
			<SetupGuideAssistant />
			{isDemoSite ? <DemoSiteTitleBar /> : <TitleBar />}
			<NavMenu />
			<ViewContainer />
			<ToastContainer />
			<MiniProfiler />
			<MarketingDialogContainer />
			<NaggingBoxContainer />
		</div>
	);
}

export default Layout;

function ViewContainer() {
	const uiState = useUiState();

	const isWelcomeGuideOpen = useRouteMatching(/^welcomeguide(\/guide)?$/);

	const setupAssistantClass =
		uiState.setupAssistantVisibilityState === SetupAssistantVisibilityState.Closed || isWelcomeGuideOpen
			? ''
			: ' ' + styles['view-container-setup-assistant'];
	const className =
		(uiState.navMenuVisible ? styles['view-container-with-nav-menu'] : styles['view-container-without-nav-menu']) + setupAssistantClass;

	return (
		<div className={className}>
			<>
				<BannerContainer />
				<Outlet />
			</>
		</div>
	);
}

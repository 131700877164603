import { useEffect } from 'react';
import { analytics } from '../utils/segment';
import { useCurrentUser } from '../contexts/UserContext';
import { useSite } from '../contexts/SiteContext';
import { useWhiteLabeling } from '../contexts/WhiteLabelingContext';

export function useSegmentIdentify(): void {
	const currentUser = useCurrentUser();
	const site = useSite();
	const { context } = useWhiteLabeling();

	useEffect(() => {
		if (analytics) {
			analytics.identify(currentUser.identifier, {
				company: {
					plan: `${site.subscription}/${site.subscriptionPaymentType}`,
					name: site.uniqueName,
					id: site.instanceGuid,
				},
				role: currentUser.userRole,
				whiteLabeling: context,
				hubSpotVID: currentUser.hubSpotVid === null ? undefined : currentUser.hubSpotVid,
				hubSpotDealId: site.hubSpotDealId === null ? undefined : site.hubSpotDealId,
				isConnectedToMSP: site.isConnectedToMSP,
				totalDevices: site.totalDeviceCount,
				activeDevices: site.activeDeviceCount,
				userIdWithSiteName: `${currentUser.identifier}_${site.uniqueName}`,
			});
		}
	}, [
		context,
		currentUser,
		site.company,
		site.instanceGuid,
		site.subscription,
		site.subscriptionPaymentType,
		site.uniqueName,
		site.hubSpotDealId,
		site.isConnectedToMSP,
		site.totalDeviceCount,
		site.activeDeviceCount,
	]);
}
